import { useContext } from 'react';
import { Col, Row, Accordion, AccordionContext, Form, useAccordionButton } from 'react-bootstrap';
import Treemap from "../charts/ExchangeTreeMap";
import NegativeValueColumn from "../charts/NegativeValuesColumn";
import anualVolume from "../data/anual-volume.json";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Form>
      <Form.Switch
        type="switch"
        className="d-flex align-items-center custom-switch"
        id={`switch-${eventKey}`}
        label={
          <div className={`d-flex align-items-center ms-2 fw-semibold ${isCurrentEventKey ? 'text-primary-emphasis' : ''}`}>
            {children}
          </div>
        }
        checked={isCurrentEventKey}
        onChange={decoratedOnClick}
      />
    </Form>
  );
}

function MainPageAccordion(props) {
  return (
    <Accordion>
      <div className="d-flex justify-content-end">
        <ContextAwareToggle eventKey="0">Gráficos</ContextAwareToggle>
      </div>
      <Accordion.Collapse eventKey="0">
        <Row className='g-3 mt-1'>
        <Col>
            <h2 className="h5 fw-semibold">Variação do market share combinado entre 2023 e 2024</h2>
            <small className='text-muted mb-0 pb-0'>Apenas pares BRL</small>
            <NegativeValueColumn data={anualVolume} symbol="Criptomoedas" />
          </Col>
          <Col lg={6}>
            <h2 className="h5 fw-semibold">Market Share nas últimas 24 horas</h2>
            <Treemap data={props.exchanges} />
          </Col>
        </Row>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default MainPageAccordion;
