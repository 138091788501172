import React from "react";
import { Row, Col, Container, Spinner, Breadcrumb, Table } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import Converter from "./components/Converter";
import coinlogo from "./components/logos/coinlogo.svg";
import NotFound from "./components/NotFound";
import NewsFeed from "./components/NewsFeed";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import { Helmet } from "react-helmet-async";
import useSWR from 'swr';
import { fetcher } from "./utils/api";
import { useCoinList } from "./utils/api";
import SymbolsTabbedContent from "./components/SymbolsTabbedContent";

function SymbolMarket() {

  const symbol = useParams().Symbol.toUpperCase()

  const { data: coinListData } = useCoinList();
  const { data: exchanges, error: exchangeError } = useSWR(`${process.env.REACT_APP_API_URL}/v1/ticker/last/exchanges/${symbol}`, fetcher, {
    refreshInterval: 1000 * 60, // 60 sec cache
    retryCount: 3,
    revalidateOnFocus: true
  })
  const { data: coinsData, error: coinsError } = useSWR(`${process.env.REACT_APP_API_URL}/v1/ticker/last?symbols=BTCBRL,USDTBRL${symbol !== 'BTCBRL' ? ',' + symbol : ''}`, fetcher, {
    refreshInterval: 20000, // 20 sec cache
    retryCount: 3,
    revalidateOnFocus: true
  });

  // Handle loading state
  const loading = !coinsData || !coinListData;
  const exchangesLoading = !exchanges;

  if (loading && !exchangeError) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  }

  if (exchangeError) {
    if (exchangeError.status === 404) {
      return <NotFound />
    } else {
      console.log(exchangeError)
      return <div className="container text-center">Error fetching data: {exchangeError?.message || coinsError?.message}</div>;
    }
  } else {
    const symbolLabels = coinListData.filter(entry => entry.Symbol === symbol)[0];
    const coins = coinsData.filter(entry => entry.Symbol === symbol);
    const btc = coinsData.filter(entry => entry.Symbol === 'BTCBRL');
    const usdt = coinsData.filter(entry => entry.Symbol === 'USDTBRL');

    let btcvar = ((coins[0].Last) / btc[0].Last);
    let btcvar24 = ((coins[0]['24h']) / btc[0]['24h']);
    let usdtvar = ((coins[0].Last) / usdt[0].Last);
    let usdtvar24 = ((coins[0]['24h']) / usdt[0]['24h']);

    const ExchangeRateSpecification = {
      "@context": "https://schema.org",
      "@type": "ExchangeRateSpecification",
      "url": "https://mercadocripto.livecoins.com.br/" + symbolLabels.Symbol,
      "name": symbolLabels.SymbolName,
      "currency": symbolLabels.Symbol.slice(0, -3),
      "currentExchangeRate": {
        "@type": "UnitPriceSpecification",
        "price": coins[0].Last,
        "priceCurrency": "BRL"
      }
    };

    const BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Criptomoedas",
          "item": "https://mercadocripto.livecoins.com.br/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": symbolLabels.SymbolName,
          "item": "https://mercadocripto.livecoins.com.br/" + symbolLabels.Symbol,
        }
      ]
    };

    const FAQPage =
      !exchangesLoading ? {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Onde comprar " + symbolLabels.SymbolName + "?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A criptomoeda" + symbolLabels.Symbol.slice(0, -3) + " pode ser negociada em corretoras centralizadas. No Brasil, para comprar diretamente com BRL (reais), a corretora de maior volume é a " + exchanges[0]?.Exchange + ", onde o par " + symbolLabels.Symbol + " teve volume de " + exchanges[0]?.QuoteVolume + " nas últimas 24 horas."
            }
          },
          {
            "@type": "Question",
            "name": "Qual é o volume diário (últimas 24 horas) de negociação do " + symbolLabels.SymbolName + "(" + symbolLabels.Symbol + ") no Brasil em reais (BRL)?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "O volume de negociação do " + symbolLabels.Symbol + " nas últimas 24 horas foi " + (exchanges?.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) + ", em um total de " + exchanges?.length + " corretoras em atividade no Brasil."
            }
          }
        ]
      } : {}

    return (
      <>
        <Helmet>
          <title>{symbolLabels.Symbol + " " + ((coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))) + " · " + symbolLabels.SymbolName + " · Preço em real brasileiro, volume e gráficos · MercadoCripto"}</title>
          <meta name="description" content={"O preço do " + symbolLabels.SymbolName + ", o volume negociado do " + symbolLabels.Symbol + " e o ágio em todas as corretoras do mercado brasileiro de criptomoedas. Acesso a gráficos do " + symbolLabels.Symbol.slice(0, -3) + " e dados históricos."} />
          <script type="application/ld+json">{JSON.stringify(ExchangeRateSpecification)}</script>
          <script type="application/ld+json">{JSON.stringify(BreadcrumbList)}</script>
          <script type="application/ld+json">{JSON.stringify(FAQPage)}</script>
        </Helmet>
        <Container>
          <Breadcrumb className="pt-2">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >
              Criptomoedas
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{symbolLabels.SymbolName}</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="d-flex mb-2">
            <Col xs="3" md="auto" className="p-1 pl-3 py-0 symbolmarket-responsive">
              <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                <title>{symbolLabels.SymbolName}</title>
                <use href={coinlogo + "#" + (symbolLabels.Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
              </svg>
            </Col>
            <Col>
              <h1 className="h2 text-nowrap ">{symbolLabels.SymbolName} <span className="text-muted h5"> {symbolLabels.Symbol.slice(0, -3)}</span></h1>
              <h2 className="h3 strong toupdate">{
                (coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  : (0.1 <= coins[0].Last && coins[0].Last > 0.0001) ? ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 8 }))
                    : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 12 }))}
              </h2>
              <h3 className="h6 text-muted strong ">{usdtvar.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} USDT
                {(usdtvar - usdtvar24) >= 0
                  ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                  : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                }
              </h3>
              {symbolLabels.SymbolName !== "Bitcoin" ?
                <h3 className="h6 text-muted strong ">{btcvar.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 8, minimumFractionDigits: 5 })} BTC
                  {(btcvar - btcvar24) >= 0
                    ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                    : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                  }
                </h3>
                : ""}
            </Col>
            <Col className="ml-4 pt-3">
              <Table borderless responsive className="openmarket-table">
                <tbody>
                  <tr>
                    <td><span className="h6">Abertura</span></td>
                    <td><span className="h6">{coins[0]['24h'].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></td>
                  </tr>
                  <tr>
                    <td><span className="h6">24h %</span></td>
                    <td>{(coins[0].Last - coins[0]['24h']) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                    </td>
                  </tr>
                  <tr>
                    <td><span className="h6">Var 24h</span></td>
                    <td>{(coins[0].Last - coins[0]['24h']) >= 0
                      ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                      : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col className="ml-4 pt-3">
              {
                exchangesLoading ? <Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>
                  :
                  <Table borderless responsive className="openmarket-table">
                    <tbody>
                      <tr><td><span className="h6">Volume 24h</span></td></tr>
                      <tr><td><span className="h6 strong toupdate"> {exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /></span></td></tr>
                      <tr><td><span className="h6 text-muted toupdate">{exchanges.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 })} {symbolLabels.Symbol.slice(0, -3)} </span></td></tr>
                    </tbody>
                  </Table>
              }
            </Col>
          </Row>
          <div className="pt-3">
            <Converter symbol={coins[0]} />
          </div>

          {exchanges && coins && symbolLabels ?
            <SymbolsTabbedContent exchanges={exchanges} exchangesLoading={exchangesLoading} coins={coins} symbolLabels={symbolLabels} />
            :
            <Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>
          }
          <div className="pt-4 ">
            <NewsFeed slug={symbolLabels.SymbolName} />
          </div>
        </Container>
      </>
    );
  }
}

export default SymbolMarket;