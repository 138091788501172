import { useState } from "react"
import ChartControl from "../charts/ChartControl"
import ComboChart from "../charts/ComboChart"
import ExchangeTable from "./ExchangeTable"
import SymbolHistoricalData from "./SymbolHistorical"
import { Badge, Container, Nav, Spinner, Tab } from "react-bootstrap"
import trustscore from "../data/trustscore.json"

function SymbolsTabbedContent({ exchanges, exchangesLoading, coins, symbolLabels }) {
  //ChartControl States
  const [frequency, setFrequency] = useState(300)
  const [interval, setIntervalsate] = useState(1440)
  const [active, setActive] = useState('1D')
  //TabbedContent States
  const [activeTab, setActiveTab] = useState('Corretoras');
  const [exchangesLoaded, setExchangesLoaded] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [historicalDataLoaded, setHistoricalDataLoaded] = useState(false);

  const handleSelect = (tab) => {
    setActiveTab(tab);
    if (tab === 'Corretoras' && !exchangesLoaded) {
      setExchangesLoaded(true);
    } else if (tab === 'Grafico' && !chartLoaded) {
      setChartLoaded(true);
    } else if (tab === 'Historicos' && !historicalDataLoaded) {
      setHistoricalDataLoaded(true);
    }
  };


  return (

    <Tab.Container justify defaultActiveKey="Corretoras" activeKey={activeTab} onSelect={handleSelect} id="tab-markets" >
      <Container className="d-block px-0">
        <Nav variant="tabs pl-3 pt-2 fw-semibold">
          <Nav.Item>
            <Nav.Link eventKey="Corretoras"><span className="text-body-secondary">Corretoras </span><Badge pill bg="gray" className="counter" title="Corretoras disponíveis">{exchanges?.length}</Badge></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Grafico"><span className="text-body-secondary">Gráfico</span></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Historicos"><span className="text-body-secondary d-block d-sm-none">Histórico</span><span className="d-none d-sm-block text-body-secondary">Dados Históricos</span></Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="mt-4">
          <Tab.Pane eventKey="Corretoras">
            <Container className="px-0 overflow-auto">
              {exchangesLoaded ? (
                <ExchangeTable
                  data={exchanges.map(item => ({ ...item, Name: item.Exchange }))}
                  price={coins[0]}
                  trust={trustscore}
                  visibleColumns={['Name', 'Spread', 'Last', 'QuoteVolume', 'Confiabilidade', 'VolumePercent']}
                />
              ) : (
                <Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>
              )}
            </Container>
          </Tab.Pane>

          <Tab.Pane eventKey="Grafico">
            {chartLoaded ? (<>
              <ChartControl
                title={symbolLabels.SymbolName + ' e Real brasileiro'}
                titleSecondary={symbolLabels.Symbol}
                frequency={frequency}
                setFrequency={setFrequency}
                interval={interval}
                setIntervalsate={setIntervalsate}
                active={active}
                setActive={setActive}
              />
              <ComboChart symbol={symbolLabels.Symbol} frequency={frequency} interval={interval} last={coins[0].Last} />
            </>) : (<Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>)}
          </Tab.Pane>

          <Tab.Pane eventKey="Historicos">
            {historicalDataLoaded ? (
              <Container className="px-0 overflow-hidden">
                <SymbolHistoricalData symbolname={symbolLabels.SymbolName} symbol={symbolLabels.Symbol} last={coins[0].Last} />
              </Container>)
              : (
                <Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner>
              )
            }

          </Tab.Pane>
        </Tab.Content>
      </Container>
    </Tab.Container>
  )
}
export default SymbolsTabbedContent