import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Container, Spinner, Alert } from 'react-bootstrap';
import RelatoriosHeader from '../components/restricted/RelatoriosHeader-closed.js';
import Error from '../utils/Error.js';
import { groupBy } from '../utils/groupBy';
import { useAuth0 } from "@auth0/auth0-react";
import { getAdminResource } from "../auth/services/message.service";
import { formatBigNumbers } from '../utils/priceFormats';
import { useTheme } from '../components/Theme/ThemeContext';

const MonthReport = ({ symbol, month, year, type }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rawreport, setRawreport] = useState([]);
  const [series, setSeries] = useState([]);
  const [dominanceSeries, setDominanceSeries] = useState([]);
  const [xaxistime, setXaxistime] = useState([]);
  const [xaxistimeUTC, setXaxistimeUTC] = useState([]);
  const { theme } = useTheme();
  const [options, setOptions] = useState({ theme: { mode: theme }, });

  const yesterday = new Date(Date.now() - 86400000);

  function calculateMaxScale(data) {
    // Initialize an array to store the sums at each index
    let sums = new Array(Math.max(...data.map(obj => obj.data.length))).fill(0);
    // Iterate over each object in the data array
    data.forEach(obj => {
      obj.data.forEach((value, index) => {
        sums[index] += value;
      });
    });
    let maxSum = Math.max(...sums);
    let exp = Math.floor(Math.log10(maxSum));
    let maxScale = Math.ceil(maxSum / Math.pow(10, exp)) * Math.pow(10, exp);
    return maxScale;
  }

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      theme: {
        mode: theme,
      },
    }));
  }, [ theme]);

  useEffect(() => {
    const fetchReport = async () => {
      const accessToken = await getAccessTokenSilently();
      setIsLoaded(false);
      setError(null);

      try {
        const yearParam = (parseInt(year) === 0 ? '' : `&year=${year}`);
        const response = await getAdminResource(accessToken, `${type}report${symbol}&month=${month}${yearParam}`);;
        const data = await response.data;

        const xaxistimeUTC = data.map(value => value.Time).filter((value, index, arr) => arr.indexOf(value) === index);
        const xaxistime = xaxistimeUTC.map(value => (new Date(value.toString().replace("T00:00:00Z", "T04:00"))).toISOString());
        const monthreport = groupBy(data, "Exchange", xaxistimeUTC);
        const totalVolume = monthreport.reduce((acc, item) => acc + item.data.reduce((a, b) => a + b, 0), 0);
        const dominance = monthreport.map(item => ({
          Name: item.name,
          VolumePercent: (item.data.reduce((a, b) => a + b, 0) / totalVolume)
        }));
        const max = calculateMaxScale(monthreport);

        setRawreport(data);
        setSeries(monthreport);
        setDominanceSeries(dominance);
        setXaxistime(xaxistime);
        setXaxistimeUTC(xaxistimeUTC);
        setIsLoaded(true);

        const chartOptions = {
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: '45%',
              dataLabels: {
                total: {
                  enabled: type === "year" ? true : false,
                  formatter: function (val) {
                    return formatBigNumbers(val)
                  },
                  style: {
                    color: '#888',
                  }
                },
              },
            }
          },
          dataLabels: {
            enabled: false
          },
          chart: {
            background: "transparent",
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: false
            },
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
              export: {
                csv: {
                  filename: type === "year" ? `mercadocripto-${symbol.split('=').pop()}-${year}${month}-12meses` : `mercadocripto-${symbol.split('=').pop()}-${year}${month}`,
                  columnDelimiter: ';',
                  headerCategory: 'Data',
                  dateFormatter: timestamp => new Date(timestamp).toLocaleDateString(),
                },
                svg: {
                  filename: type === "year" ? `mercadocripto-${symbol.split('=').pop()}-${year}${month}-12meses` : `mercadocripto-${symbol.split('=').pop()}-${year}${month}`,
                },
                png: {
                  filename: type === "year" ? `mercadocripto-${symbol.split('=').pop()}-${year}${month}-12meses` : `mercadocripto-${symbol.split('=').pop()}-${year}${month}`,
                },
              },
            },
          },
          colors: ['#f0b90b', '#2f78e2', '#4caf50', '#3f51b5', '#ff9800'],
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          }],
          legend: {
            width: '150',
            fontSize: '10px',
            position: 'right',
            offsetY: 40,
          },
          stroke: {
            width: 0.5,
            curve: 'smooth',
            lineCap: "butt",
            colors: ['transparent'],
          },
          tooltip: {
            shared: false,
            followCursor: true,
            marker: {
              show: true,
            },
            x: {
              show: true,
              format: type === "year" ? 'MM/yyyy' : 'dd/MM/yyyy',
            },
            y: {
              formatter: (value, { series }) => `${parseFloat(value.toFixed(2)).toLocaleString('pt-BR')} ${symbol.split('=').pop().slice(0, -3)}`,
            },
          },
          crosshairs: {
            show: false,
          },
          xaxis: {
            axisBorder: {
              color: '#bbc7d200',
            },
            tickPlacement: 'between',
            type: 'datetime',
            categories: xaxistime,
          },
          yaxis: {
            tickAmount: 4,
            decimalsInFloat: 0,
            max: max,
            min: 0,
            labels: {
              offsetX: -30,
              minWidth: 90,
              formatter: function (val) {
                return formatBigNumbers(val)
              },
              style: {
                fontSize: '11px',
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            forceNiceScale: true,
            crosshairs: {
              show: false
            },
          },
          noData: {
            text: 'Carregando...'
          },
          grid: {
            padding: {
              left: -30
            },
            strokeDashArray: 2,
          },
        };

        setOptions((prevOptions) => ({
          ...prevOptions,
          ...chartOptions,
        }));
      } catch (error) {
        setError(error);
        setIsLoaded(true);
      }
    };

    fetchReport();
  }, [symbol, month, year, type, getAccessTokenSilently]); 

  if (new Date(year, month - 1, 1).getTime() >= yesterday) {
    return (
      <Container className="p-5">
        <Alert variant="warning" className="bd-callout p-4 border-warning">
          <Alert.Heading>Esses dados ainda não estão disponíveis.</Alert.Heading>
          <p>Somente os dados anteriores à data atual estão disponíveis para consulta. Favor escolher outra data.<br />
            Se você acha que essa mensagem é um erro, por gentileza, entre em contato.</p>
          <hr />
          <div>
            <a href='mailto:mercadocripto@livecoins.com.br' title="Contato" className="btn btn-lg btn-dark">E-mail</a>
          </div>
        </Alert>
      </Container>
    );
  } else if (error) {
    error.message = "Estamos processando sua solicitação. Favor tentar novamente em alguns instantes."
    return <Error error={error} />;
  } else if (!isLoaded) {
    return (
      <Container className="text-center p-5">
        <Spinner variant="primary" className="m-5" animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
        <p className="fs-5">Carregando, por favor aguarde...</p>
      </Container>
    );
  } else {
    return (
      <>
        <RelatoriosHeader rawreport={rawreport} dominance={dominanceSeries} xaxistime={xaxistime} xaxistimeUTC={xaxistimeUTC} symbol={symbol.split('=').pop()} series={series} type={type} />
        <Container className="pt-3 ">
          <Chart options={options} series={series} type="bar" height={350} />
        </Container>
      </>
    );
  }
};

export default MonthReport;
