import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';

const ChartControl = ({
    title,
    titleSecondary,
    setFrequency,
    setIntervalsate,
    active,
    setActive,
    buttons = [
        { id: "1D", label: "1D", frequency: 300, intervalsate: 1440 },
        { id: "7D", label: "7D", frequency: 600, intervalsate: 10080 },
        { id: "1M", label: "1M", frequency: 3600, intervalsate: 43200 },
        { id: "3M", label: "3M", frequency: 7200, intervalsate: 129600 },
        { id: "YTD", label: "YTD", frequency: 28800, intervalsate: Math.floor((Date.now() - Date.parse(new Date().getFullYear(), 0, 0)) / 86400000) * 1440 },
        { id: "1A", label: "1A", frequency: 86400, intervalsate: 525600 },
        { id: "MAX", label: "MAX", frequency: 259200, intervalsate: Math.floor((Date.now() -  new Date('2010-07-01')) / 86400000) * 1440 },
    ],
}) => {

    const handleClick = (f, i) => (e) => {
        const clicked = e.target.id;
        setFrequency(f);
        setIntervalsate(i);
        if (active === clicked) {
            setActive('');
        } else {
            setActive(clicked)
        }
    }

    return (
        <Row className='mt-4'>
            <Col className='my-auto'>
                <h4 className="h6" style={{ fontWeight: "600" }}>{title} <span className="h6 ml-1 text-muted fw-semibold"> {titleSecondary}</span></h4>
            </Col>
            <Col md="auto">
                <div className="p-1 rounded" id="chart-control">
                    {buttons.map((button) => (
                        <Button
                            key={button.id}
                            className={`mr-1 fw-semibold border-0 ${active === button.id ? 'active bg-body-secondary' : ''}`}
                            variant="transparent"
                            id={button.id}
                            size="sm"
                            onClick={handleClick(button.frequency, button.intervalsate)}
                        >
                            {button.label}
                        </Button>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default ChartControl;
