import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import { useTheme } from '../components/Theme/ThemeContext';
import logo from '../components/logos/mercadocripto-logo.png';

function calculateYearlyChange(data, symbol) {
    const result = [];
    const currentYear = data[1][2024][symbol];
    const previousYear = data[0][2023][symbol];

    Object.keys(currentYear).forEach(key => {
        const currentValue = currentYear[key];
        const previousValue = previousYear[key];
        const change = currentValue - previousValue;

        result.push({
            x: key,
            y: change
        });
    });
    return result.sort((a, b) => b.y - a.y);
}

const NegativeValueColumn = (props) => {
    const { theme } = useTheme();
    const [options, setOptions] = useState({

        series: [{
            name: 'Variação de Market Share',
            data: calculateYearlyChange(props.data, props.symbol)
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    colors: {
                        ranges: [{
                            from: -100,
                            to: -0.001,
                            color: '#F15B46'
                        }, {
                            from: 0,
                            to: 100,
                            color: '#00a83e'
                        }]
                    },
                    columnWidth: '80%',
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return val.toFixed(1) + "%";
                },
            },
            yaxis: {
                title: {
                    text: 'Variação de Market Share (%)',
                    style: {
                        fontSize: '14px',
                        fontFamily: 'sans-serif',
                        fontWeight: 600,
                    }
                },
                labels: {
                    formatter: function (y) {
                        return y.toFixed(1) + "%";
                    }
                }
            },
            xaxis: {
                type: 'category',
                labels: {
                    rotate: 0
                }
            }
        },
    });

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            options: {
                theme: {
                    mode: theme,
                },
            }
        }));
    }, [theme]);

    return (
        <div className="position-relative">
            <div className="rounded ms-2 ratio ratio-16x9">
                <ReactApexChart options={options.options} series={options.series} type="bar" width="100%" height="95%"/>
            </div>
            <img src={logo} alt="MercadoCripto" className={`position-absolute translate-middle-x opacity-75 ${theme === 'dark' ? '' : 'invertcolor'}`} style={{ width: '23%', right: '52%', bottom: '15%' }} />
        </div>
    );
}

export default NegativeValueColumn;