import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts'
import { Spinner } from 'react-bootstrap';
import { useTheme } from '../components/Theme/ThemeContext';

export default function DcaChart({ symbol, data, isLoaded }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({})
  const [chartseries, setChartSeries] = useState([{
    name: symbol + ' Acumulado',
    type: 'area',
    data: []
  }, {
    name: 'Total Investido',
    type: 'area',
    data: []
  }, {
    name: 'Valor em R$',
    type: 'area',
    data: []
  }]);

  function extractData(dataList) {
    const btcStash = [];
    const timeList = [];
    const totalInvested = [];
    const brlStash = [];

    for (const item of dataList) {
      btcStash.push(item.btcStash);
      timeList.push(item.time);
      totalInvested.push(item.totalInvested);
      brlStash.push(item.brlStash);
    }

    return { btcStash, timeList, totalInvested, brlStash };
  }
  useEffect(() => {
    const { btcStash, timeList, totalInvested, brlStash } = extractData(data);
    setChartSeries([{
      name: symbol + ' Acumulado',
      type: 'area',
      data: btcStash
    }, {
      name: 'Total Investido',
      type: 'area',
      data: totalInvested
    }, {
      name: 'Valor',
      type: 'area',
      data: brlStash
    }]);
    setOptions({
      labels: timeList,
      yaxis: [{
        max: function (max) { return max * 2.5},
        min:0,
        show: false,
        seriesName: 'Bitcoin Acumulado',
        opposite: true,
        labels: {
          style: {
            colors: '#737980',
          }
        }
      }, {
        show: true,
        seriesName: 'Total Investido',
        forceNiceScale: true,
        labels: {
          offsetX: -30,
          minWidth: 90,
          formatter: function (value) {
            return 'R$ ' + (value).toLocaleString('pt-BR', { style: 'decimal' })
          },
        },
      }, {
        seriesName: 'Total Investido', show: false
      }
      ],
      chart: {
        background: 'transparent',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
          tools: {
            download: true,
            zoom: true,
            pan: true,
            reset: true,
          },
        },
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        foreColor: "#bbc7d2",
        height: 430,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width:  2,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.8,
          opacityFrom: 0.8,
          opacityTo: 0.5,
          stops: [10, 90]
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        type: 'datetime',
        axisTicks: {
          color: "#f5f7ff"
        },
        axisBorder: {
          show: false,
        },
        labels: {
          format: 'dd/MM/yyyy',
        }
      },
      grid: {
        padding: {
          left: -70
        },
        borderColor: "#e1e1e1",
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: 'dd/MM/yyyy HH:mm',
        },
        y: [{ formatter: (value) => (value.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 8 })) }, {
          formatter: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }, {
          formatter: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        }],
      },
      theme:{
        mode: theme
      },
      noData: {
        text: 'Carregando...'
      },
      legend: { show: false },
    })
  }, [data, symbol,theme]);


  if (!isLoaded || data === undefined) {
    return <div className="text-center mt-5" style={{ height: "350px" }}><Spinner variant="primary" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner><br />Carregando... </div>;
  } else {
    return (
      <div style={{ height: "350" }} >
        <Chart options={options} series={chartseries} type="area" height={350} />
      </div>
    );
  }
}