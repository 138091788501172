import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts'
import { Spinner } from 'react-bootstrap';
import { ReactComponent as Caret } from '../components/caret-up-fill.svg';
import { useTheme } from '../components/Theme/ThemeContext';
import { useComboChartData } from '../utils/api';

const ComboChart = (props) => {
  const { theme } = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const [options, setOptions] = useState({xaxis: {type: 'datetime',labels: {format: 'dd/MM/yyyy',}}});
  const [chartseries, setChartSeries] = useState([{
    data: [],
    name: "Preço",
    type: "area"
  },
  {
    data: [],
    name: "Volume 24h",
    type: "area"
  }]);

  const { data, error } = useComboChartData(props.frequency, props.symbol, props.interval);

  useEffect(() => {
    if (data && data.length > 0) {
      const combochart = data.map(x => ({
        'x': x.Time,
        'y': x.Last
      }));
      const combochartvol = data.map(x => ({
        'x': x.Time,
        'y': x.Volume * x.Last
      }));
      setChartSeries([{
        name: "Preço",
        type: "area",
        data: combochart
      }, {
        name: "Volume 24h",
        type: "area",
        data: combochartvol
      }]);
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [data]);


  useEffect(() => {
    if (isLoaded) {
      setOptions({
        colors: [(chartseries[0].data[0].y <= chartseries[0].data[chartseries[0].data.length - 1].y) ? '#1eb980' : '#dc3545', '#a7afb755'],
        yaxis: [{
          type: 'numeric',
          seriesName: 'Preço',
          forceNiceScale: true,
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false,
            intersect: true,
            shared: false
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            offsetX: -70,
            minWidth: 90,
            formatter: function (value) {
              return (value).toLocaleString('pt-BR', { style: 'decimal', miniumSignificantDigits: 0 })
            },
            style: {
              colors: '#737980',
            }
          },
        }, {
          max: function (max) { return max * 2 },
          min: Math.min.apply(null, chartseries[1].data.map((v) => v.y)),
          show: false,
          crosshairs: {
            show: false
          },
          opposite: true,
          forceNiceScale: true,
          tickAmount: 5,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            offsetX: -70,
            formatter: function (value) {
              return (value).toLocaleString('pt-BR', { style: 'decimal', miniumSignificantDigits: 0 })
            },
            style: {
              colors: '#737980',
            }
          },
        }],
        theme: {
          mode: theme
        },
        chart: {
          background: 'transparent',
          id: 'combochart',
          type: 'area',
          stacked: false,
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          animations: {
            enabled: false
          },
          foreColor: "#bbc7d2",
          height: 430,
          toolbar: {
            autoSelected: 'selection',
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            }
          },
          selection: {
            enabled: true,
            type: 'x',
            fill: {
              color: '#24292e',
              opacity: 0.1
            },
            stroke: {
              width: 1,
              dashArray: 0,
              color: '#24292e',
              opacity: 0.1
            },
          },
          events: {
            selection: function (chartContext, { xaxis }) {
              var closestmin = chartseries[0].data.map(x => ({ 'x': Date.parse(x.x), 'y': x.y }))
                .map(k => ({ k, delta: Math.abs(xaxis.min - k.x) }))
                .sort((a, b) => a.delta - b.delta)[0].k;
              var closestmax = chartseries[0].data.map(x => ({ 'x': Date.parse(x.x), 'y': x.y }))
                .map(k => ({ k, delta: Math.abs(xaxis.max - k.x) }))
                .sort((a, b) => a.delta - b.delta)[0].k;
              var signal = (-closestmin.y + closestmax.y)

              let user_selection = document.getElementById('user_selection');
              const onSelectionMove = (e) => {
                if (document.querySelector('.apexcharts-selection-rect')) {
                  user_selection.style.left = document.querySelector('.apexcharts-selection-rect').getBoundingClientRect().left - 70 + "px";
                  user_selection.innerHTML = "<span class='text-nowrap text" + ((signal >= 0) ? "-success'>+" : "-danger'>") + (-closestmin.y + closestmax.y).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + " (" + ((-closestmin.y + closestmax.y) / closestmin.y).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
                    ")</span><br><small class='text-muted'>" + new Date(closestmin.x).toLocaleString([], { dateStyle: 'short', timeStyle: 'short', hour12: false }) + " - " + new Date(closestmax.x).toLocaleString([], { dateStyle: 'short', timeStyle: 'short', hour12: false }) + "</small>"
                }
                else { }
              }
              const onSelectionDown = (e) => { user_selection.style.visibility = 'visible'; }
              const onSelectionClear = (e) => {
                user_selection.style.visibility = 'hidden';
                setOptions({
                  chart: {
                    selection: {
                      xaxis: {
                        min: undefined,
                        max: undefined
                      }
                    }
                  }
                })
              }
              document.addEventListener('mousemove', onSelectionMove);
              document.addEventListener('mousedown', onSelectionDown);
              document.addEventListener('mouseup', onSelectionClear);
            },
            updated: function (chartContext, config) {
              setOptions({
                chart: {
                  selection: {
                    xaxis: {
                      min: undefined,
                      max: undefined
                    }
                  }
                }
              });
              document.getElementById('user_selection').style.visibility = "hidden";
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: [2, 1],
        },
        fill: {
          type: ['gradient', 'solid'],
          gradient: {
            shadeIntensity: 0.8,
            opacityFrom: 0.8,
            opacityTo: 0.5,
            stops: [10, 90]
          },
        },
        xaxis: {
          tooltip: {
            enabled: false,
          },
          type: 'datetime',
          axisTicks: {
            color: "#f5f7ff"
          },
          axisBorder: {
            show: false,
          },
          labels: {
            format: 'dd/MM/yyyy',
          }
        },
        grid: {
          padding: {
            left: -70
          },
          borderColor: "#e1e1e1",
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: 'dd/MM/yyyy HH:mm',
          },
          y: {
            formatter: (value) => (value > 0.1) ?
              value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) :
              ("R$ " + value.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))
            ,
          },
        },
        noData: {
          text: 'Carregando...'
        },
        legend: { show: false },
      })
    }
  }, [chartseries, data, isLoaded, theme]);

  const clearSelection = () => {
    setOptions({
      chart: {
        selection: {
          enabled: false
        },
        xaxis: {
          min: undefined,
          max: undefined
        }
      }
    });
    document.getElementById('user_selection').innerHTML = "";
  }

  if (error) {
    return <>Error in API {error.message}</>;
  } else if (!isLoaded) {
    return <div className="text-center mt-5" style={{ height: "350px" }}><Spinner variant="primary" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner><br />Carregando... </div>;
  } else if (isLoaded) {
    let btcvar = props.last
    var btcvar24 = chartseries[0].data[0].y
    let intervalo = "";
    if (props.interval === 1440) { intervalo = "hoje" }
    else if (props.interval === 10080) { intervalo = "últimos 7 dias" }
    else if (props.interval === 43200) { intervalo = "último mês" }
    else if (props.interval === 129600) { intervalo = "últimos 3 meses" }
    else if (props.interval === 525600) { intervalo = "último ano" }
    else if (props.interval === Math.floor((Date.now() -  new Date('2010-07-01')) / 86400000) * 1440 ) { intervalo = "desde o começo" }
    else { intervalo = "acumulado no ano" }

    return (
      <div style={{ height: "350" }} >
        {(chartseries[0].data[chartseries[0].data.length - 1].y - chartseries[0].data[0].y) >= 0
          ? <span className="h6 text-success text-nowrap">+{(btcvar - btcvar24).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })} ({((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}) <Caret className="icontable" /> {intervalo}</span>
          : <span className="h6 text-danger text-nowrap">{(btcvar - btcvar24).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })} ({((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })})  <Caret className="icon-rotated" /> {intervalo}</span>
        }
        <div id="user_selection" className="position-absolute mt-1" style={{ fontSize: "92%", fontWeight: "600" }}></div>
        <Chart options={options} series={chartseries} type="area" height={350} onMouseUp={clearSelection} tabIndex="0" />
      </div>
    );
  }
}

export default ComboChart;